<!--suppress SpellCheckingInspection -->
<template>
  <el-scrollbar :native="false" style="margin-top: 34px">
    <div style="max-height:calc(100vh - 34px)">
      <el-row type="flex">
        <el-col :span="18" :offset="3">
          <div style="display: flex">
            <PageHeader title="API Documentation"></PageHeader>
            <el-button @click="downloadPostmanCollection" style="border: none"
              ><img
                :src="require('@/assets/images/postman.png')"
                height="50px"
              />
              <div style="margin-top: 15px">
                {{ __("Download Postman Collection") }}
              </div>
            </el-button>
          </div>
        </el-col>
      </el-row>
      <el-col :span="18" :offset="3">
        <div class="api-documentation-container">
          <h2>{{ __("There are 2 APIs for QforMe") }}</h2>
          <ol class="indent">
            <li>
              <a href="#check_waiter">{{
                __("Check waiter availability for a queue")
              }}</a>
            </li>
            <li>
              <a href="#add_waiter">{{
                __("Add a new waiter to the queue")
              }}</a>
            </li>
          </ol>
          <br />

          <h2 id="check_waiter">1. {{ __("Check waiter") }}</h2>

          <h3>{{ __("Call URL") }}</h3>
          <p></p>
          <p>{{ `${backendUrl}/qforme-api/check-waiter` }}</p>

          <h3>{{ __("HTTP Method") }}</h3>
          <p>{{ __("POST") }}</p>

          <h3>{{ __("Headers") }}</h3>
          <br />

          <el-row type="flex">
            <el-col>
              <table class="table table-bordered table-striped">
                <tbody>
                  <tr>
                    <td><strong>studio-api-key</strong></td>
                    <td>
                      <strong>{{ __("REQUIRED") }}</strong>
                    </td>
                    <td>
                      {{
                        __("Any of the User API Key generated for user/account")
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </el-col>
          </el-row>

          <h3>{{ __("Parameters") }}</h3>

          <el-row type="flex">
            <el-col>
              <table class="table table-bordered table-striped">
                <tbody>
                  <tr>
                    <td><strong>queue_api_key</strong></td>
                    <td>
                      <strong>{{ __("REQUIRED") }}</strong>
                    </td>
                    <td>
                      {{ queue.api_key }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </el-col>
          </el-row>

          <h3>{{ __("Example Request") }}</h3>
          <ol class="prettyprint">
            <li>
              curl --location -XPOST
              {{ `${backendUrl}/qforme-api/check-waiter` }} \
            </li>
            <li>
              --header 'studio-api-key: <b>YOUR STUDIO/USER API KEY</b>' \
            </li>
            <li>--form 'queue_api_key={{ queue.api_key }}' \</li>
          </ol>

          <h3>{{ __("Response") }}</h3>
          <p style="margin-top:10px;">
            {{ __("A response will be returned in JSON format.") }}
          </p>
          <vue-json-pretty
            :data="checkWaiterResponse"
            :highlightMouseoverNode="true"
            :highlightSelectedNode="true"
          >
          </vue-json-pretty>

          <br />

          <h2 id="add_waiter">2. {{ __("Add waiter to queue") }}</h2>

          <h3>{{ __("Call URL") }}</h3>
          <p></p>
          <p>{{ `${backendUrl}/qforme-api/add-waiter` }}</p>

          <h3>{{ __("HTTP Method") }}</h3>
          <p>POST</p>

          <h3>{{ __("Headers") }}</h3>
          <br />

          <el-row type="flex">
            <el-col>
              <table class="table table-bordered table-striped">
                <tbody>
                  <tr>
                    <td><strong>studio-api-key</strong></td>
                    <td>
                      <strong>{{ __("REQUIRED") }}</strong>
                    </td>
                    <td>
                      {{
                        __("Any of the User API Key generated for user/account")
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </el-col>
          </el-row>

          <h3>Parameters</h3>

          <el-row type="flex">
            <el-col>
              <table class="table table-bordered table-striped">
                <tbody>
                  <tr>
                    <td><strong>queue_api_key</strong></td>
                    <td>
                      <strong>{{ __("REQUIRED") }}</strong>
                    </td>
                    <td>
                      {{ queue.api_key }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>waiter_token</strong></td>
                    <td>
                      <strong>{{ __("REQUIRED") }}</strong>
                    </td>
                    <td>
                      {{ __("Waiter token returned by the") }}
                      <i>{{ __("Check Waiter") }}</i> API.
                    </td>
                  </tr>
                  <tr>
                    <td><strong>waiter_uuid</strong></td>
                    <td>
                      <strong>{{ __("REQUIRED") }}</strong>
                    </td>
                    <td>{{ __("UUID of the call") }}</td>
                  </tr>
                  <tr>
                    <td><strong>transfer_phone_number</strong></td>
                    <td>
                      <strong>{{ __("REQUIRED") }}</strong>
                    </td>
                    <td>
                      <!-- eslint-disable-next-line -->
                      {{ __("Customer number where call will be transfered once connected to call center.") }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>caller_info_text</strong></td>
                    <td>
                      <strong>{{ __("OPTIONAL") }}</strong>
                    </td>
                    <td>
                      <!-- eslint-disable-next-line -->
                      {{ __("Caller information in text form that is played to the call center agent when connected before transferring to the customer.") }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </el-col>
          </el-row>

          <h4>{{ __("Usage Notes") }}</h4>
          <ul class="indent">
            <li></li>
            <li></li>
            <li></li>
          </ul>

          <h3>{{ __("Example Request") }}</h3>
          <ol class="prettyprint">
            <li>
              curl --location -XPOST
              {{ `${backendUrl}/qforme-api/add-waiter` }} \
            </li>
            <li>
              --header 'studio-api-key: <b>YOUR STUDIO/USER API KEY</b>' \
            </li>
            <li>
              --header 'Content-Type: application/x-www-form-urlencoded' \
            </li>
            <li>--data-urlencode 'queue_api_key={{ queue.api_key }}' \</li>
            <li>--data-urlencode 'waiter_token=' \</li>
            <li>--data-urlencode 'waiter_uuid=' \</li>
            <li>--data-urlencode 'transfer_phone_number=' \</li>
            <li>--data-urlencode 'caller_info_text='</li>
          </ol>

          <h3>{{ __("Response") }}</h3>
          <p style="margin-top:10px;">
            {{ __("A response will be returned in JSON format.") }}
          </p>
          <vue-json-pretty
            :data="checkWaiterResponse"
            :highlightMouseoverNode="true"
            :highlightSelectedNode="true"
          >
          </vue-json-pretty>
          <br />
        </div>
      </el-col>
    </div>
  </el-scrollbar>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import { getPostmanCollection } from "@/api/qforme";
import FileDownloader from "@/mixins/FileDownloader";
import VueJsonPretty from "vue-json-pretty";
import _ from "lodash";

export default {
  name: "queueApiInfo",
  mixins: [FileDownloader],
  components: {
    PageHeader,
    VueJsonPretty
  },
  props: {
    queue: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      backendUrl: process.env.VUE_APP_BACKEND_BASE_API,
      checkWaiterResponse: {
        token: "fbf05c524237e53fbac51341c1c65f161cfa9c51",
        message: __("Waiter available."),
        status_code: 200
      },
      addWaiterResponse: {
        message: __("Waiter added to queue."),
        status_code: 200
      }
    };
  },
  methods: {
    downloadPostmanCollection() {
      getPostmanCollection(this.queue).then(res => {
        this.downloadFile(
          res.data,
          _.snakeCase(this.queue.queue_name) + ".postman_collection.json"
        );
      });
    }
  }
};
</script>

<style scoped>
.api-documentation-container {
  position: relative;
  margin: 15px 0;
  padding: 39px 19px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.api-documentation-container::after {
  content: "API Documentation";
  position: absolute;
  top: -1px;
  left: -1px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  color: #9da0a4;
  -webkit-border-radius: 4px 0 4px 0;
  -moz-border-radius: 4px 0 4px 0;
  border-radius: 4px 0 4px 0;
}

table {
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}

.table {
  width: 100%;
  margin-bottom: 20px;
}

.table th,
.table td {
  padding: 8px;
  line-height: 20px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #dddddd;
}

.table th {
  font-weight: bold;
}

.table thead th {
  vertical-align: bottom;
}

.table caption + thead tr:first-child th,
.table caption + thead tr:first-child td,
.table colgroup + thead tr:first-child th,
.table colgroup + thead tr:first-child td,
.table thead:first-child tr:first-child th,
.table thead:first-child tr:first-child td {
  border-top: 0;
}

.table tbody + tbody {
  border-top: 2px solid #dddddd;
}

.table .table {
  background-color: #ffffff;
}

.table-condensed th,
.table-condensed td {
  padding: 4px 5px;
}

.table-bordered {
  border: 1px solid #dddddd;
  border-collapse: separate;
  *border-collapse: collapse;
  border-left: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.table-bordered th,
.table-bordered td {
  border-left: 1px solid #dddddd;
}

.table-bordered caption + thead tr:first-child th,
.table-bordered caption + tbody tr:first-child th,
.table-bordered caption + tbody tr:first-child td,
.table-bordered colgroup + thead tr:first-child th,
.table-bordered colgroup + tbody tr:first-child th,
.table-bordered colgroup + tbody tr:first-child td,
.table-bordered thead:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child th,
.table-bordered tbody:first-child tr:first-child td {
  border-top: 0;
}

.table-bordered thead:first-child tr:first-child > th:first-child,
.table-bordered tbody:first-child tr:first-child > td:first-child,
.table-bordered tbody:first-child tr:first-child > th:first-child {
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
}

.table-bordered thead:first-child tr:first-child > th:last-child,
.table-bordered tbody:first-child tr:first-child > td:last-child,
.table-bordered tbody:first-child tr:first-child > th:last-child {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
}

.table-bordered thead:last-child tr:last-child > th:first-child,
.table-bordered tbody:last-child tr:last-child > td:first-child,
.table-bordered tbody:last-child tr:last-child > th:first-child,
.table-bordered tfoot:last-child tr:last-child > td:first-child,
.table-bordered tfoot:last-child tr:last-child > th:first-child {
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
}

.table-bordered thead:last-child tr:last-child > th:last-child,
.table-bordered tbody:last-child tr:last-child > td:last-child,
.table-bordered tbody:last-child tr:last-child > th:last-child,
.table-bordered tfoot:last-child tr:last-child > td:last-child,
.table-bordered tfoot:last-child tr:last-child > th:last-child {
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
}

.table-bordered tfoot + tbody:last-child tr:last-child td:first-child {
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
}

.table-bordered tfoot + tbody:last-child tr:last-child td:last-child {
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
  -moz-border-radius-bottomright: 0;
}

.table-bordered caption + thead tr:first-child th:first-child,
.table-bordered caption + tbody tr:first-child td:first-child,
.table-bordered colgroup + thead tr:first-child th:first-child,
.table-bordered colgroup + tbody tr:first-child td:first-child {
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
}

.table-bordered caption + thead tr:first-child th:last-child,
.table-bordered caption + tbody tr:first-child td:last-child,
.table-bordered colgroup + thead tr:first-child th:last-child,
.table-bordered colgroup + tbody tr:first-child td:last-child {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -moz-border-radius-topright: 4px;
}

.table-striped tbody > tr:nth-child(odd) > td,
.table-striped tbody > tr:nth-child(odd) > th {
  background-color: #f9f9f9;
}

.table-hover tbody tr:hover > td,
.table-hover tbody tr:hover > th {
  background-color: #f5f5f5;
}

.prettyprint {
  padding: 12px;
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
  border-radius: 6px;
  list-style: none;
}
</style>
